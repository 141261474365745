
// Grid item SVG animation
function iconmenu_svg_animation() {

  var path_selector = 'path.plb-2';

  jQuery("a.site-logo").hover(function(event) {


    gsap.fromTo("#path", {drawSVG: "0 5%"}, {duration: 1, drawSVG: "95% 100%"});

    var svg_found = jQuery(this).find(path_selector);
    if(svg_found.length > 0) {
      gsap.to(svg_found, 0, {drawSVG:"0%"});
      setTimeout(function(){
        gsap.to(svg_found, 1.5, {drawSVG:true});
      },200);
    }
  },function(event){
      var svg_found = jQuery(this).find(path_selector);
      if(svg_found.length > 0) {
        gsap.to(svg_found, 0.5, {drawSVG:true});
      }
  });

}